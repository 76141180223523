// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-anthony-road-js": () => import("./../../../src/pages/anthony-road.js" /* webpackChunkName: "component---src-pages-anthony-road-js" */),
  "component---src-pages-burwood-road-js": () => import("./../../../src/pages/burwood-road.js" /* webpackChunkName: "component---src-pages-burwood-road-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-home-builder-east-york-js": () => import("./../../../src/pages/custom-home-builder-east-york.js" /* webpackChunkName: "component---src-pages-custom-home-builder-east-york-js" */),
  "component---src-pages-custom-home-building-js": () => import("./../../../src/pages/custom-home-building.js" /* webpackChunkName: "component---src-pages-custom-home-building-js" */),
  "component---src-pages-featherstone-ave-js": () => import("./../../../src/pages/featherstone-ave.js" /* webpackChunkName: "component---src-pages-featherstone-ave-js" */),
  "component---src-pages-gwendolen-cres-js": () => import("./../../../src/pages/gwendolen-cres.js" /* webpackChunkName: "component---src-pages-gwendolen-cres-js" */),
  "component---src-pages-home-additions-js": () => import("./../../../src/pages/home-additions.js" /* webpackChunkName: "component---src-pages-home-additions-js" */),
  "component---src-pages-home-renovation-js": () => import("./../../../src/pages/home-renovation.js" /* webpackChunkName: "component---src-pages-home-renovation-js" */),
  "component---src-pages-ig-landing-js": () => import("./../../../src/pages/ig-landing.js" /* webpackChunkName: "component---src-pages-ig-landing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infused-js": () => import("./../../../src/pages/infused.js" /* webpackChunkName: "component---src-pages-infused-js" */),
  "component---src-pages-lord-seaton-road-js": () => import("./../../../src/pages/lord-seaton-road.js" /* webpackChunkName: "component---src-pages-lord-seaton-road-js" */),
  "component---src-pages-luxury-home-builders-east-york-js": () => import("./../../../src/pages/luxury-home-builders-east-york.js" /* webpackChunkName: "component---src-pages-luxury-home-builders-east-york-js" */),
  "component---src-pages-mckee-ave-js": () => import("./../../../src/pages/mckee-ave.js" /* webpackChunkName: "component---src-pages-mckee-ave-js" */),
  "component---src-pages-nesbit-drive-js": () => import("./../../../src/pages/nesbit-drive.js" /* webpackChunkName: "component---src-pages-nesbit-drive-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-rear-home-additions-js": () => import("./../../../src/pages/rear-home-additions.js" /* webpackChunkName: "component---src-pages-rear-home-additions-js" */),
  "component---src-pages-second-floor-additions-east-york-js": () => import("./../../../src/pages/second-floor-additions-east-york.js" /* webpackChunkName: "component---src-pages-second-floor-additions-east-york-js" */),
  "component---src-pages-second-floor-additions-js": () => import("./../../../src/pages/second-floor-additions.js" /* webpackChunkName: "component---src-pages-second-floor-additions-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-tangemere-road-js": () => import("./../../../src/pages/tangemere-road.js" /* webpackChunkName: "component---src-pages-tangemere-road-js" */),
  "component---src-pages-vida-moderna-js": () => import("./../../../src/pages/vida-moderna.js" /* webpackChunkName: "component---src-pages-vida-moderna-js" */),
  "component---src-pages-woodville-ave-toronto-js": () => import("./../../../src/pages/woodville-ave-toronto.js" /* webpackChunkName: "component---src-pages-woodville-ave-toronto-js" */)
}

